import { Canvas, useThree } from '@react-three/fiber';
import { Box, Plane, Backdrop, SpotLight, Stage, OrbitControls, PerspectiveCamera, CameraControls, ContactShadows } from '@react-three/drei';
import * as THREE from 'three';
import FitnessBike from '../models/FitnessBike';
import Treadmill from '../models/Treadmill';
import { gsap } from 'gsap';
import { useLayoutEffect, useRef, useState } from 'react';

export default function DemoBlack(props) {
    const comp = useRef(document.querySelector("body"));
    const [lookat, setLookat] = useState({ x: 0, y: .5, z: 0 });

    const camera = useThree(state => state.camera);
    const fitnessBikeRef = useRef();
    const treadmillRef = useRef();
    const spotLightRef = useRef();
    const spotLightRef2 = useRef();

    const startX = 2;
    const startY = 1;
    const startZ = 2;


    camera.position.set(startX, startY, startZ);
    camera.lookAt(lookat.x, lookat.y, lookat.z);

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            const tl1 = gsap.timeline({
                scrollTrigger: {
                    onUpdate: function () {
                        camera.lookAt(lookat.x, lookat.y, lookat.z);
                    },
                    scroller: ".sb-blocks-fitness__sections-container",
                    immediateRender: false,
                    trigger: document.querySelector("#test-section1"),
                    start: "top top",
                    pin: true,
                    end: "bottom center",
                    scrub: true,
                },
            });
            tl1.to(camera.position, {
                x: 2,
                y: .2,
                z: -1
            })
            tl1.to(lookat, {
                x: 0,
                y: .5,
                z: 0,
            }, "<");


            const tl2 = gsap.timeline({
                scrollTrigger: {
                    onUpdate: function () {
                        camera.lookAt(lookat.x, lookat.y, lookat.z);
                    },
                    scroller: ".sb-blocks-fitness__sections-container",
                    immediateRender: false,
                    trigger: document.querySelector("#test-section2"),
                    start: "top center",
                    pin: false,
                    scrub: true,
                    end: "center center",
                },
            });
            tl2.to(fitnessBikeRef.current.position, {
                x: 0,
                y: 0,
                z: -2,
            })
            tl2.to(treadmillRef.current.position, {
                x: 0,
                y: 0,
                z: 0,
            }, "<");


            // Content anim
            gsap.fromTo(
                document.querySelector("#test-section2"), {
                opacity: 0
            },
                {
                    opacity: 1,
                    scrollTrigger: {
                        scroller: ".sb-blocks-fitness__sections-container",
                        trigger: document.querySelector("#test-section2"),
                        start: "top center",
                        end: "center center",
                        scrub: true,
                    },
                }
            );
        }, comp);
        return () => ctx.revert();
    })





    return (
        <>

            {props.isBlack &&
                <mesh receiveShadow position={[0, 0, 0]} rotation-x={-Math.PI / 2}>
                    <planeGeometry args={[5, 200]} />
                    <meshStandardMaterial />
                </mesh>
            }
            {
                (props.isBlack) && (
                    <>
                        <Backdrop
                            receiveShadow={true}
                            scale={[200, 4, 1]}
                            position={[-2, -1, 0]}
                            rotation={[0, Math.PI * .5, 0]}
                            floor={4} // Stretches the floor segment, 0.25 by default
                            segments={20} // Mesh-resolution, 20 by default
                        >
                            <meshStandardMaterial color="white" />
                        </Backdrop>

                        <ambientLight intensity={0.01} />
                        <SpotLight ref={spotLightRef} position={[1, 2, -3]}  castShadow penumbra={1} distance={6} angle={0.3} attenuation={5} anglePower={4} intensity={3} /></>)
            }
            <group scale={.8} ref={fitnessBikeRef}>
                <FitnessBike />
            </group>
            <group scale={.5} position={[0, 0, 3]} ref={treadmillRef}>
                <Treadmill rotation={[0, Math.PI*.3, 0]} />
            </group>
            {
                (!props.isBlack) && (
                    <>
                        <ambientLight intensity={0.1} />
                        <ContactShadows></ContactShadows>
                        <pointLight intensity={1} position={[0, 3, 0]}></pointLight>
                        <pointLight position={[5, 3, 5]}></pointLight></>)
            }

        </>
    );
}
