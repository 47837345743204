import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './views/App';


import { gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);



document.addEventListener("DOMContentLoaded", function () {
  const blocks = document.querySelectorAll(".sb-blocks-fitness__instance--update-me");
  for (const block of blocks) {

    block.classList.remove("sb-blocks-fitness__instance--update-me");
    const root = createRoot(block);

    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>);
  }
});