import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Treadmill(props) {
  const { nodes, materials } = useGLTF("/models/fitness-teadmill.glb");
  return (
    <group scale={.2} {...props} dispose={null}>
      <group rotation={[Math.PI / 2, 0, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.treadmill_1.geometry}
          material={materials.blinn1SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.treadmill_2.geometry}
          material={materials.initialShadingGroup}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.treadmill_3.geometry}
          material={materials.blinn2SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.treadmill_4.geometry}
          material={materials.lambert2SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.treadmill_5.geometry}
          material={materials.lambert3SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.treadmill_6.geometry}
          material={materials.blinn3SG}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.treadmill_7.geometry}
          material={materials.lambert4SG}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/models/fitness-teadmill.glb");