/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function FitnessBike(props) {
  const { nodes, materials } = useGLTF("/models/fitness-bike.glb");
  return (
    <group {...props} dispose={null}>
      <group position={[0, 1.08, -0.23]} rotation={[0.17, 0, 0]} scale={0.01}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh028.geometry}
          material={materials.Buttons}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh028_1.geometry}
          material={materials["Seat-and-handles"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh028_2.geometry}
          material={materials.Steel}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh028_3.geometry}
          material={materials["Black-plastic"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh028_4.geometry}
          material={materials["01 - Default"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh028_5.geometry}
          material={materials.Tubes}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh028_6.geometry}
          material={materials.Hull}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh028_7.geometry}
          material={materials["Top-hull"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Mesh028_8.geometry}
          material={materials.Indicator}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/models/fitness-bike.glb");