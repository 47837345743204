import '../styles/App.css';
import { Canvas } from '@react-three/fiber';
import Demo from '../components/Demo';
import DemoBlack from '../components/DemoBlack';
import { useEffect, useState } from 'react';

let setupListener = false;
function App() {
  const [isBlack, setIsBlack] = useState(true);

  function toggleBlack() {
    console.log("toggle")
    setIsBlack(!isBlack);
  }

  useEffect(function () {
    if (setupListener ) {
      return;
    }
    setupListener = true;

    const toggleEl = document.querySelector("#dark-mode-toggle");

    toggleEl.addEventListener("click", function () {
      const bodyEl = document.querySelector("body");
      if (bodyEl.classList.contains("sb-dark-mode")) {
        console.log("has dark mode");
        bodyEl.classList.remove("sb-dark-mode");
        setIsBlack(false);
      } else {
        console.log("has no dark mode");
        bodyEl.classList.add("sb-dark-mode");
        setIsBlack(true);
      }
    }.bind(this));
  }, []);



  return (
    <>
      <Canvas shadows camera={{ position: [4, -1, 8], fov: 35 }}>
        {isBlack && <color attach="background" args={['#010101']} />}
        {!isBlack && <color attach="background" args={['white']} />}
        {isBlack && <fog attach="fog" args={['#202020', 5, 20]} />}

        <DemoBlack isBlack={isBlack}></DemoBlack>
      </Canvas>

    </>
  );
}

export default App;
